import React, { useState, useSyncExternalStore } from "react";
import Heading from "../../common/Heading";
// image
import mail from "../../../assets/images/contact/mail.png";
import phone from "../../../assets/images/contact/phone.png";
import location from "../../../assets/images/contact/location.png";
import { sendMail } from "../../../services/api";
import toast from "react-hot-toast";
function Form() {
  const initial = {
    name: "",
    state: "",
    email: "",
    contact: "",
    comment: "",
    type: "contact",
  };
  const [formData, setFormData] = useState(initial);
  const [sending, setSending] = useState(false);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setSending(true);

      let res = await sendMail(formData);
      if (res.status === 200) toast.success("Email sent successfully.");

      setSending(false);
      setFormData(initial);
    } catch (error) {
      setSending(false);
      toast.error("Something went wrong.");
    }
  }
  return (
    <div className="flex flex-col gap-10 mt-10 tab950:pr-28 pr-5 pl-5 tab950:pl-28">
      <Heading
        helperText={
          "We’re easily accessible and would love to speak to you about all your marketing and technical queries."
        }
      ></Heading>
      <div className="grid grid-cols-1 tablets:grid-cols-2 pr-8 pl-8 gap-5">
        <ContactDetails></ContactDetails>
        <div className="flex order-first tablets:order-last flex-col gap-4">
          <span className="text-gray-400 text-[13px] font-medium">
            Please help us to reach you!
          </span>
          <div className="flex flex-col gap-4 ">
            <input
              className="p-3 border placeholder-gray-300 "
              placeholder="Full Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            ></input>
            <input
              className="p-3 border placeholder-gray-300 "
              placeholder="State"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            ></input>
            <input
              className="p-3 border placeholder-gray-300 "
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            ></input>
            <input
              className="p-3 border placeholder-gray-300 "
              placeholder="Mobile"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              required
            ></input>
            <textarea
              className="p-3 border resize-none placeholder-gray-300 "
              placeholder="Comment"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              required
            ></textarea>
            <button
              disabled={sending}
              type="submit"
              onClick={handleSubmit}
              className={`${sending ? 'bg-[#186c9a]' : 'bg-[#1D94D2]'}  text-white self-start p-2 pr-7 pl-7`}
            >
              {sending ? (
                <svg
                  className="animate-spin"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  height={25}
                  width={25}
                >
                  <title>loading</title>
                  <path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ContactDetails() {
  return (
    <div className="flex order-last tablets:order-first flex-col gap-5">
      <span className="text-[13px] font-medium text-gray-400">
        Get In Touch!
      </span>
      <div className="flex gap-5 ">
        <img
          className="min-w-[25px] max-h-[30px]"
          src={location}
          alt="location"
        />
        <div className="flex text-gray-400 text-[12px] flex-col">
          <span>
            <strong>Drytech Corporate Office: </strong>T/13-14, 3rd Floor,
            Pinnacle Business Park, Mahakali Caves Rd, Andheri East, Mumbai,
            Maharashtra 400093
          </span>
          <span>
            <strong>Drytech Production Office: </strong>Nagpur Road, Pandhurna,
            Dist – Pandhurna, M.P – 480334.
          </span>
        </div>
      </div>
      <div className="flex gap-5 ">
        <img className="min-w-[25px] max-h-[30px]" src={phone} alt="location" />
        <div className="flex text-gray-400 text-[12px] flex-col">
          <span>+91-22-22-68592401/403</span>
        </div>
      </div>
      <div className="flex gap-5 ">
        <img className="min-w-[25px] max-h-[30px]" src={mail} alt="location" />
        <div className="flex text-gray-400 text-[12px] flex-col">
          <span>marketing@drytechindia.com</span>
        </div>
      </div>
    </div>
  );
}

export default Form;
