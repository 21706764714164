import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link"; // Import HashLink
import logo from "../../assets/images/nav/logo-new.jpg";
import { useNavigate } from "react-router-dom";
//data
import {
  dropdownItems,
  initial,
  initialNested,
  nestedDropdownMenu,
  pages,
} from "./data";
import HamNav from "./HamNav";
function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(initial);
  const [nestedDropdown, setNestedDropdownOpen] = useState(initialNested);
  const [nestedDropdownPosition, setNestedDropdownPosition] = useState({
    top: 0,
  });
  const navigate = useNavigate();
  const dropdownItemRefs = useRef({});
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true); // If user has scrolled down more than 50px
      } else {
        setIsScrolled(false); // If user has scrolled back to the top
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function makeHash(link) {
    let newLink = link.replace(/\s+/g, "-").toLowerCase();
    return newLink;
  }

  const handleMouseEnter = (page) => {
    setDropdownOpen({ ...initial, [page]: true });
  };

  const handleMouseEnterNested = (itemLabel, pageLabel) => {
    const parentItem = dropdownItemRefs.current[itemLabel];

    if (parentItem) {
      const { top } = parentItem.getBoundingClientRect();
      setNestedDropdownPosition({ top: top - 80 });
    }

    setNestedDropdownOpen({ ...initialNested, [itemLabel]: true });
  };

  const handleMouseLeave = () => {
    setDropdownOpen({ ...initial });
    setNestedDropdownOpen({ ...initialNested });
  };

  const handleMouseLeaveNested = () => {
    setNestedDropdownOpen({ ...initialNested });
  };

  const handleClick = (e) => {
    if (true) {
      e.preventDefault(); // Prevent navigation
    }
  };

  return (
    <header
    className={`nav-container sticky top-0 w-full z-10 bg-white flex flex-wrap justify-between items-center tab950:justify-evenly p-5 gap-5 shadow-md ${
      isScrolled ? "p-3" : "p-5"
    }`}
  >
    <div
      onClick={() => navigate("/")}
      className={`cursor-pointer nav-logo transition-all duration-300 ${
        isScrolled ? "w-[130px]" : "w-[150px]"
      }`}
    >
      <img className="w-full" src={logo} alt="dry_tech_logo" />
    </div>
      {/* Main Nav  */}
      <div className="nav-links hidden tab950:flex">
        <ul className="list-none flex gap-10 uppercase">
          {pages.map((page) => (
            <li
              key={page.label}
              className="group text-[14px] relative"
              onMouseEnter={() =>
                page.hasDropdown ? handleMouseEnter(page.label) : null
              }
            >
              {/* Use NavLink for regular navigation */}
              {page.to.startsWith("/") ? (
                <NavLink
                  to={makeHash(page.to)}
                  className={({ isActive }) =>
                    isActive
                      ? "underline underline-offset-8 decoration-blue-400"
                      : "hover:underline hover:underline-offset-8 hover:decoration-blue-400"
                  }
                >
                  {page.label}
                </NavLink>
              ) : (
                <NavLink
                  to={page.to}
                  onClick={handleClick}
                  className={({ isActive }) =>
                    isActive
                      ? "underline underline-offset-8 decoration-blue-400"
                      : "hover:underline hover:underline-offset-8 hover:decoration-blue-400"
                  }
                >
                  {page.label}
                </NavLink>
              )}

              {page.hasDropdown && dropdownOpen[page.label] && (
                <div
                  className="absolute transition-all top-9 left-0 shadow-md z-10 border-t-2 border-blue-400 bg-white p-3 flex flex-col gap-3"
                  onMouseEnter={() => handleMouseEnter(page.label)}
                  onMouseLeave={handleMouseLeave}
                >
                  {dropdownItems[page.label].map((item) => (
                    <>
                      {item.to.startsWith("/") ? (
                        <NavLink
                          ref={(el) =>
                            (dropdownItemRefs.current[item.label] = el)
                          }
                          key={item.label}
                          to={makeHash(item.to)}
                          className="block py-1 text-[12px] w-[120px] hover:border-b-[1px]"
                          onMouseEnter={() =>
                            item.hasDropdown
                              ? handleMouseEnterNested(item.label, page.label)
                              : null
                          }
                          onClick={() => setDropdownOpen({ ...initial })}
                        >
                          {item.label}
                        </NavLink>
                      ) : (
                        <HashLink
                          smooth
                          to={makeHash(item.to)}
                          ref={(el) =>
                            (dropdownItemRefs.current[item.label] = el)
                          }
                          key={item.label}
                          className="block py-1 text-[12px] hover:border-b-[1px]"
                          onMouseEnter={() =>
                            item.hasDropdown
                              ? handleMouseEnterNested(item.label, page.label)
                              : null
                          }
                          onClick={() => setDropdownOpen({ ...initial })}
                        >
                          {item.label}
                        </HashLink>
                      )}

                      {/* Nested Dropdown */}
                      {item.hasDropdown && nestedDropdown[item.label] && (
                        <div
                          className="absolute transition-all left-[100%] shadow-md z-10 border-t-2 border-blue-400 bg-white p-3 flex flex-col gap-3"
                          style={{ top: nestedDropdownPosition.top }}
                          onMouseEnter={() =>
                            handleMouseEnterNested(item.label, page.label)
                          }
                          onMouseLeave={handleMouseLeaveNested}
                        >
                          {nestedDropdownMenu[item.label].map((nestedItem) => (
                            <NavLink
                              key={nestedItem.label}
                              to={makeHash(nestedItem.to)}
                              className="block py-1 text-[12px] w-[100px] hover:border-b-[1px]"
                              onClick={() =>
                                setNestedDropdownOpen({ ...initialNested })
                              }
                            >
                              {nestedItem.label}
                            </NavLink>
                          ))}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      {/* // ham nav started from here  */}
      <div className="nav-ham tab950:hidden">
        <HamNav></HamNav>
      </div>
    </header>
  );
}

export default Navbar;
